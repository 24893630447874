<template>
    <div>
        <div class="welcome">
            <div class="ui two column middle aligned grid">
                <div class="column" style="flex: 1; min-width: 0;">
                    <h2 class="ui header">
                        <div class="sub header">Hi {{_user|displayname}}</div>
                        <div class="ui hidden fitted divider"></div>
                        <div class="ui hidden fitted divider"></div>
                        Welcome back!
                    </h2>
                </div>
                <div class="computer only tablet only right aligned column" style="width: auto;">
                    <div class="ui purple horizontal statistic">
                        <div class="value">
                            {{today|formatdate("DD")}}
                        </div>
                        <div class="label">
                            <div class="ui left aligned text">
                                <div>{{today|formatdate("MMM YYYY")}}</div>
                                <div class="ui grey text" style="font-weight: 600;">{{today|formatdate("dddd")}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="ui hidden divider"></div>

            <div class="dashboard-content">
                <div>
                    <div class="ui two stackable cards">
                        <div class="ui card">
                            <div class="content">
                                <h3 class="ui purple header">Consultations</h3>
                                <div class="meta"></div>
                                <div class="description">
                                    <p>Begin new Consultation?</p>
                                </div>
                            </div>
                            <div class="ui extra center aligned content">
                                <a class="ui alt circular link fluid button" href="javascript:void(0);" @click="newConsultation">
                                    <i class="add icon"></i> New Consultation
                                </a>
                            </div>
                        </div>
                        <div class="ui card">
                            <div class="content">
                                <h3 class="ui purple header">Treatment Prescriptions</h3>
                                <div class="meta"></div>
                                <div class="description">
                                    <p>Create new Treatment Prescription?</p>
                                </div>
                            </div>
                            <div class="ui extra center aligned content">
                                <div ref="customersearch" class="ui search">
                                    <div class="ui icon fluid input">
                                        <input class="prompt" type="text" placeholder="Customer ID, name, birth date(YYYY-MM-DD), contact no...">
                                        <i class="search icon"></i>
                                    </div>
                                    <div class="results"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <template v-if="pending_aftersales_prescriptions.length">
                    <div class="ui hidden divider"></div>

                    <div>
                        <div class="ui segment">
                            <h3 class="ui purple header">Pending Prescriptions</h3>
                            <div class="content">
                                <p>You have <label class="ui red circular small label">{{pending_aftersales_prescriptions_pagination.total_count}}</label> treatment prescription{{(pending_aftersales_prescriptions_pagination.total_count > 1)? "s" : ""}} with after sales pending</p>
                                <div class="ui hidden divider"></div>
                                <pending-after-sales-prescriptions :prescriptions="pending_aftersales_prescriptions" :pagination="pending_aftersales_prescriptions_pagination" @change:page="changePendingAftersalesPrescriptionsPage" @save:aftersales="afterSalesCreated"/>
                            </div>
                        </div>
                    </div>
                </template>

            </div>
        </div>

        <div ref="consultationmodal" class="ui hidden consultation modal">
            <div class="content">
                <consultation-form ref="consultationform" @submitted="consultationFormSubmitted" @cancel="cancelConsultationForm" :key="`consultationform_${consultationform_key}`"/>
            </div>
        </div>

        <div ref="prescriptionmodal" class="ui hidden prescription modal">
            <div class="content">
                <prescription-form ref="prescriptionform" :id="`prescriptionform${_uid}`" :customer="selected_customer" :prescription="prescriptionformdata" @change:saving="prescriptionSavingListener" @save:prescription="prescriptionSaved"/>
            </div>
            <div class="actions">
                <button type="submit" :form="`prescriptionform${_uid}`" class="ui submit teal button" :class="{loading: saving_prescription}" :disabled="saving_prescription">Save</button>
                <button type="button" class="ui alt red cancel button">Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>
import ConsultationForm from "@/views/consultation/ConsultationForm";
import PrescriptionForm from "@/views/consultation/PrescriptionForm";
import PendingAfterSalesPrescriptions from "@/views/PendingAfterSalesPrescriptions";

export default {
    name: "dashboard",
    components: {
        ConsultationForm,
        PrescriptionForm,
        PendingAfterSalesPrescriptions
    },
    data() {
        return {
            today: new Date(),
            selected_customer: null,
            prescriptionformdata: {},
            saving_prescription: false,
            consultationform_key: (new Date()).getTime(),
            pending_aftersales_prescriptions: [],
            pending_aftersales_prescriptions_pagination: {
                total_count: 0,
                page: 1,
                size: 5
            },
            opened_modals: [],
            history_popped: false
        };
    },
    created() {
        this.listPendingAfterSales();
    },
    mounted() {
        window.addEventListener("popstate", this.popstateHandler);

        $(this.$refs.consultationmodal).modal({
            closable: false,
            onVisible: () => {
                history.pushState(null, "consultationmodal_opened");
                this.opened_modals.push("consultationmodal");
            },
            onHide: () => {
                //maybe use onHidden?
                if(!this.history_popped) {
                    history.back();
                }

                this.opened_modals = this.opened_modals.filter((modal) => {
                    return modal !== "consultationmodal";
                });
            },
            onHidden: () => {
                this.consultationform_key = (new Date()).getTime();
            }
        });

        $(this.$refs.prescriptionmodal).modal({
            closable: false,
            onVisible: () => {
                history.pushState(null, "prescriptionmodal_opened");
                this.opened_modals.push("prescriptionmodal");
            },
            onHide: () => {
                //maybe use onHidden?
                if(!this.history_popped) {
                    history.back();
                }

                this.opened_modals = this.opened_modals.filter((modal) => {
                    return modal !== "prescriptionmodal";
                });
            }
        });

        $(this.$refs.customersearch).search({
            minCharacters: 3,
            apiSettings: {
                responseAsync: (settings, callback) => {
                    let query = settings.urlData.query.trim();
                    if(query) {
                        let params = {
                            name: `*${query}*`,
                            id_no: query,
                            contact_no: query
                        };

                        try {
                            let Tdob = this.$moment(query, "YYYY-MM-DD", true);
                            if(Tdob.isValid()) {
                                params.dob = query;
                            }
                        } catch(error) {}

                        this.$http.get("customers", {
                            params: {
                                search: JSON.stringify(params)
                            }
                        }).then((response) => {
                            callback(response);
                        });
                    }
                }
            },
            fields: {
                results: "data",
                title: "name"
            },
            onSelect: (result, response) => {
                this.addPrescription(result);
            }
        });
    },
    beforeDestroy() {
        window.removeEventListener("popstate", this.popstateHandler);
    },
    methods: {
        popstateHandler(event) {
            this.history_popped = true;
            this.opened_modals.forEach((modal) => {
                $(this.$refs[modal]).modal("hide");
            });

            this.history_popped = false;
        },
        listPendingAfterSales() {
            this.$http.get(`users/${this._user.id}/prescriptions-pending-aftersales`, {
                params: Object.assign({}, this.pending_aftersales_prescriptions_pagination)
            }).then((response) => {
                this.pending_aftersales_prescriptions = response.data;

                const total_count = response.headers["x-total-count"];
                this.pending_aftersales_prescriptions_pagination.total_count = total_count? +total_count : this.pending_aftersales_prescriptions.length;
            }).catch((error) => {
                console.error(error);
                this.$toasted.error("Unable to list prescriptions with aftersales pending.", {
                    duration: 3000
                });
            });
        },
        newConsultation() {
            $(this.$refs.consultationmodal).modal("show");
        },
        consultationFormSubmitted() {
            $(this.$refs.consultationmodal).modal("hide");
        },
        cancelConsultationForm() {
            $(this.$refs.consultationmodal).modal("hide");
        },
        afterSalesCreated(aftersales) {
            this.pending_aftersales_prescriptions = this.pending_aftersales_prescriptions.filter((prescription) => {
                return prescription.id !== aftersales.id;
            });
        },
        addPrescription(customer) {
            this.selected_customer = customer;
            this.prescriptionformdata = {
                customer_id: customer.id
            };

            $(this.$refs.prescriptionmodal).modal("show");
        },
        prescriptionSavingListener(saving) {
            this.saving_prescription = saving;
        },
        prescriptionSaved(prescription) {
            $(this.$refs.prescriptionmodal).modal("hide");

            this.selected_customer = null;

            this.listPendingAfterSales();
        },
        changePendingAftersalesPrescriptionsPage(page) {
            this.pending_aftersales_prescriptions_pagination.page = page;
            this.listPendingAfterSales();
        }
    }
};
</script>

<style lang="scss" scoped>
.dashboard-content {
    max-width: 800px;
}

.ui.consultation.modal {
    margin: 0 !important;
    inset: 0;
    width: auto;
    border-radius: 0;

    .content {
        ::v-deep {
            .consultation-form--wrapper {
                max-width: 768px;
                margin: auto;
            }
        }
    }
}

.ui.search {
    input {
        text-overflow: ellipsis;
    }

    > .results {
        width: 100%;
    }
}
</style>