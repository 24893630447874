<template>
    <div>
        <table class="ui very basic unstackable responsive top aligned table">
            <thead>
                <tr>
                    <th>Customer</th>
                    <th>Main Treatment</th>
                    <th></th>
                </tr>
            </thead>
            <template v-if="prescriptions.length">
                <transition-group tag="tbody" name="vue-server-side-paginated-list" mode="out-in">
                    <tr v-for="prescription in prescriptions" :key="`prescription_tr_${prescription.id}`">
                        <td data-title="Customer">
                            <router-link :to="{
                                name: 'Customer',
                                params: {
                                    id: prescription.customer.id
                                }
                            }" title="View Profile">
                                <strong>{{prescription.customer.name}}</strong>
                            </router-link>
                        </td>
                        <td data-title="Main Treatment">
                            <div>
                                <router-link :to="{
                                    name: 'Prescription',
                                    params: {
                                        id: prescription.id
                                    }
                                }" title="View Treatment Prescription">
                                    <strong>{{prescription.main_treatment_code}}</strong> {{prescription.main_treatment_description}}
                                </router-link>
                            </div>
                            <div class="ui light text">Date / Time: {{prescription.prescription_datetime|formatdate("DD MMM YYYY [at] hh:mmA")}}</div>
                        </td>
                        <td class="middle aligned" :class="{'right aligned': !is_sm}">
                            <a href="javascript:void(0);" @click="createAfterSales(prescription)">Create after sales record<i class="angle right icon"></i></a>
                        </td>
                    </tr>
                </transition-group>
            </template>
            <tbody v-else>
                <tr>
                    <td colspan="3">No treatment with after sales pending found.</td>
                </tr>
            </tbody>
            <tfoot v-show="prescriptions.length">
                <tr>
                    <th colspan="3" class="right aligned">
                        <pagination-menu ref="paginationmenu" :total-count="pagination.total_count" :start-page="pagination.page" :page-size="pagination.size" @page-changed="changePage"/>
                    </th>
                </tr>
            </tfoot>
        </table>

        <div ref="aftersalesmodal" class="ui hidden modal">
            <div class="content">
                <h3 class="ui header">
                    New After Sales Record
                    <div class="sub meta header">
                        <span class="ui black customer text">{{(selected_prescription && selected_prescription.customer)? selected_prescription.customer.name : ""}}</span>
                        <span class="ui black text">/</span>
                        <span class="ui black treatment text">{{(selected_prescription)? `${selected_prescription.main_treatment_code} ${selected_prescription.main_treatment_description}` : ""}}</span>
                    </div>
                </h3>
                <div class="ui hidden divider"></div>
                <after-sales-form :id="`aftersalesmodalform${_uid}`" :formdata="aftersalesformdata" @change:saving="afterSalesSavingListener" @save:aftersales="afterSalesSaved"/>
            </div>
            <div class="actions">
                <button type="submit" :form="`aftersalesmodalform${_uid}`" class="ui submit teal button" :class="{loading: saving_aftersales}" :disabled="saving_aftersales">Submit</button>
                <button type="button" class="ui alt red cancel button">Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>
import PaginationMenu from "@/components/PaginationMenu";
import AfterSalesForm from "@/views/consultation/AfterSalesForm";

export default {
    name: "pending-aftersales-prescriptions",
    components: {
        PaginationMenu,
        AfterSalesForm
    },
    props: {
        prescriptions: {
            type: Array,
            required: true
        },
        pagination: {
            type: Object,
            default() {
                return {
                    total_count: 0,
                    page: 1,
                    size: 10
                };
            }
        }
    },
    data() {
        return {
            aftersalesformdata: {},
            selected_prescription: null,
            saving_aftersales: false,
            opened_modals: [],
            history_popped: false
        };
    },
    mounted() {
        window.addEventListener("popstate", this.popstateHandler);

        $(this.$refs.aftersalesmodal).modal({
            closable: false,
            onVisible: () => {
                history.pushState(null, "aftersalesmodal_opened");
                this.opened_modals.push("aftersalesmodal");
            },
            onHide: () => {
                //maybe use onHidden?
                if(!this.history_popped) {
                    history.back();
                }

                this.opened_modals = this.opened_modals.filter((modal) => {
                    return modal !== "aftersalesmodal";
                });
            }
        });
    },
    beforeDestroy() {
        window.removeEventListener("popstate", this.popstateHandler);
    },
    methods: {
        popstateHandler(event) {
            this.history_popped = true;
            this.opened_modals.forEach((modal) => {
                $(this.$refs[modal]).modal("hide");
            });

            this.history_popped = false;
        },
        changePage(page) {
            this.$emit("change:page", page);
        },
        createAfterSales(presription) {
            this.selected_prescription = presription;

            this.aftersalesformdata = {
                id: presription.id
            };

            $(this.$refs.aftersalesmodal).modal("show");
        },
        afterSalesSavingListener(saving) {
            this.saving_aftersales = saving;
        },
        afterSalesSaved(aftersales) {
            this.$emit("save:aftersales", aftersales);

            $(this.$refs.aftersalesmodal).modal("hide");

            this.selected_prescription = null;
        }
    }
};
</script>

<style lang="scss" scoped>
.sub.meta.header {
    margin-top: 0.5em;

    > * {
        margin-right: 0.75em;
        font-weight: 600;
    }
}
</style>