import { render, staticRenderFns } from "./PendingAfterSalesPrescriptions.vue?vue&type=template&id=61c08f7e&scoped=true&"
import script from "./PendingAfterSalesPrescriptions.vue?vue&type=script&lang=js&"
export * from "./PendingAfterSalesPrescriptions.vue?vue&type=script&lang=js&"
import style0 from "./PendingAfterSalesPrescriptions.vue?vue&type=style&index=0&id=61c08f7e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61c08f7e",
  null
  
)

export default component.exports