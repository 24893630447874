<template>
    <div class="consultation-form--wrapper">
        <keep-alive>
            <component ref="form" :is="form" :formdata="formdata" :actions="actions" @submit="next" @prev="prev" @cancel="cancel"/>
        </keep-alive>
    </div>
</template>

<script>
import PersonalInfoForm from "@/views/consultation/PersonalInfoForm";
import TodayVisitForm from "@/views/consultation/TodayVisitForm";
import MedicalInfoForm from "@/views/consultation/MedicalInfoForm";

export default {
    name: "consultation-form",
    components: {
        PersonalInfoForm,
        TodayVisitForm,
        MedicalInfoForm
    },
    data() {
        return {
            form: "personal-info-form",
            forms: [
                "personal-info-form",
                "today-visit-form",
                "medical-info-form"
            ],
            formdata: {}
        };
    },
    computed: {
        exclude() {
            return (process.env.NODE_ENV === "production")? "" : /.+/;
        },
        actions() {
            let actions = {};

            switch(this.form) {
                case "personal-info-form":
                    actions = {
                        cancel: {
                            text: "Cancel & Exit"
                        },
                        next: {
                            text: "Next"
                        }
                    };
                    break;
                case "today-visit-form":
                    actions = {
                        cancel: {
                            text: "Cancel & Exit"
                        },
                        prev: {
                            text: "Back"
                        },
                        next: {
                            text: "Continue"
                        }
                    };
                    break;
                case "medical-info-form":
                    actions = {
                        cancel: {
                            text: "Cancel & Exit"
                        },
                        prev: {
                            text: "Back"
                        },
                        next: {
                            text: "Finish & Submit"
                        }
                    };
                    break;
            }

            return actions;
        }
    },
    methods: {
        next(formdata) {
            Object.assign(this.formdata, formdata);

            let form_index = this.forms.indexOf(this.form);
            if(form_index > -1) {
                if(form_index < this.forms.length - 1) {
                    this.$refs.form.submitting = false;
                    this.form = this.forms[form_index + 1];

                    this.$nextTick(() => {
                        this.$emit("next");
                    });
                } else if(form_index === this.forms.length - 1) {
                    this.$confirm({
                        title: "Saving Consultation",
                        message: "By clicking <strong>I Agree</strong>, you agree to our Privacy Policy and Terms of Service",
                        button: {
                            yes: "I Agree",
                            no: "Cancel"
                        },
                        callback: (confirmed) => {
                            if(confirmed) {
                                this.$http.post("consultations", this.formdata).then((response) => {
                                    this.$toasted.success("Consultation saved successfully.", {
                                        duration: 3000,
                                        action: {
                                            text: "OK",
                                            onClick: (e, toast) => {
                                                this.$emit("submitted", response.data);
                                                toast.goAway(0);
                                            }
                                        },
                                        onComplete: () => {
                                            this.$emit("submitted", response.data);
                                        }
                                    });
                                }).catch((error) => {
                                    console.error(error);

                                    this.$toasted.error("Unable to create consultation.", {
                                        action: [
                                            {
                                                text: "REPORT ERROR",
                                                href: `mailto:tech@1991skincare.com?subject=Error%20submitting%20consultation&body=${encodeURIComponent(JSON.stringify(this.formdata))}`
                                            },
                                            {
                                                text: "CLOSE",
                                                onClick: (e, toast) => {
                                                    this.$refs.form.submitting = false;
                                                    toast.goAway(0);
                                                }
                                            }
                                        ],
                                        onComplete: () => {
                                            this.$refs.form.submitting = false;
                                        }
                                    });
                                });
                            } else {
                                this.$refs.form.submitting = false;
                            }
                        }
                    });
                }
            }
        },
        prev(formdata) {
            let form_index = this.forms.indexOf(this.form);
            if(form_index) {
                this.form = this.forms[form_index - 1];

                this.$nextTick(() => {
                    this.$emit("prev");
                });
            }
        },
        cancel() {
            this.$emit("cancel");
            this.form = this.forms[0];
            this.formdata = {};
        }
    }
};
</script>